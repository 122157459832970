<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <!--
      <div class="card p-fluid">
        <h5>{{ $t('menu.warehouse.create')}}</h5>
        <div class="field grid">
					<label for="packageCode" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('package.packageCode')}}</label>
					<div class="col-12 lg:col-4 md:col-4">
						<InputText id="packageCode" type="text" v-model="packageCode" />
					</div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.save')" @click="onSave($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.reset')" @click="onReset($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.back')" @click="onBack()"></Button>
            </div>
				</div>

      </div>
      -->
      <CrudTable ref="shipment" :entities="entities"
        :meta="metaTable"
        @tableAction="onTableAction"
        @itemAction="onItemAction"
        @cell-edit-complete="onCellEditComplete"
        @paging="onPaging"
        @filter="onFilter"       
        @sort="onSort"/>
    </div>
    <DialogShipmentForm :entity="currentEntity" :visible="showDialogForm"
					@hide="showDialogForm=false"
					@cancel="showDialogForm=false"
					@save="saveEntityInfo($event)"/>
  </div>
</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import DialogShipmentForm from "./DialogShipmentForm.vue";
import ShipmentService from '@/service/ShipmentService';
import AuthService from '@/service/AuthService';
import adjustment from '@/utils/adjustment';
import { converter } from '@/utils/common';
import formatter from '@/utils/formatter';
import { Consts } from '@/utils/consts';
import {serializeOrders, serializeFilters } from '@/utils/params';
import mixin_list from '@/mixins/list';

export default {
  components: {CrudTable, DialogShipmentForm},
  mixins: [mixin_list],
  data() {
    return {
      entities: [],
      packageCode: null,
      currentEntity: null,
      currentUser: null,
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS
      },
      showDialogForm: false,
      timeout: 5000,
      metaTable : {
        dateFormat: Consts.FORMAT_DATE_VN,
        entityName: "shipment",
        expander: false,
        searchable: true,
        rowEditor: true,
        editMode: "cell",
        sortMode: "multiple",
        multiSortMeta: [{field:"arrival", order: -1}],
        actionsHeaderStyle: "width:6rem; text-align: center;",
        paginator:{
          enable: true,
          rows: Consts.DEFAULT_PAGE_ROWS,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} shipments",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "arrival",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType: "date",
            sortable: true,
            formatter: formatter.formatDate
          },
          {
            name: "shipmentCode",
            headerStyle: "width:10%; min-width:8rem;",
            filter: true,
            sortable: true,
            link: this.openLink
          },
          {
            name: "referenceCode",
            headerStyle: "width:10%; min-width:8rem;",
            filter: true,
            sortable: true,
          },
          {
            name: "awbCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
            editor: {
              enable: true,
              style:"width: 120px"
            },
          },
          {
            name: "weightAwbKg",
            headerStyle: "width:10%; min-width:12rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatKgWeight
          },
          {
            name: "weightPartnerLbs",
            headerStyle: "width:10%; min-width:13rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "weightActualKg",
            headerStyle: "width:10%; min-width:13rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatKgWeight
          },
          {
            name: "packageNumber",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
          },
          {
            name: "packageType",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
          },
          {
            name: "status",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
          },
          {
            name: "note",
            headerStyle: "width:20%; min-width:18rem;",
            filter: true,
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          },
        ],
        itemActions: [
          {
            name: "store",
            icon: "pi pi-database",
            style: "p-button-rounded mr-2",
          },
          // {
          //   name: "preview",
          //   icon: "pi pi-print",
          //   style: "p-button-rounded mr-2"
          // },
          // {
          //   name: "confirm",
          //   icon: "pi pi-send",
          //   style: "p-button-rounded mr-2",
          //   condition:"status === 'inited'"
          // }
        ]
      }
    }
  },
  mounted() {
    //this.initColumnFilters(this.$refs.dt, this.filters);
    this.currentUser = AuthService.currentUser;
    this.service = ShipmentService;
    this.filterOptions.sorts = serializeOrders({multiSortMeta: this.metaTable.multiSortMeta});
    this._loadEntities();
  },
  beforeUnmount() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
  },
  methods: {
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size =pageEvent.rows;
      this._loadEntities();
		},
    onFilter(filterEvent) {
      let filter = serializeFilters(filterEvent);
      if (this.filterOptions.filter != filter) {
        this.filterOptions.filter = filter;
         this._loadEntities();
      }
    },
    onSort(sortEvent) {
      let sorts = serializeOrders(sortEvent);
      if (this.filterOptions.sorts != sorts) {
        this.filterOptions.sorts = serializeOrders(sortEvent);
         this._loadEntities();
      }
    },
   _loadEntities() {
      this.service.getAll(this.filterOptions).then(data => {
				if(Array.isArray(data.content)) {
          data.content.forEach(item => adjustment.adjustShipment(item));
          this.entities = data.content;
          this.metaTable.paginator.totalRecords = data.totalElements;
        } else {
          this.entities = [];
          this.metaTable.paginator.totalRecords = 0;
        }
			});
    },
    onTableAction(sAction) {
      if(sAction == 'create') {
        this.$router.push({ name: 'agent_shipment_edit', params: {} });
      }
      console.log(sAction);
    },
    onItemAction(sAction, entity) {
      if (sAction == 'store') {
        ShipmentService.update(entity.id, {status: 'imported'}).then(() => {
          this.$router.push({ name: 'warehouse_receipt_edit', params: {shipmentId: entity.id} });
        });
      } else if (sAction == 'preview') {
        //this.$router.push({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        // const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        // window.open(routeData.href);         
      } 
    },
    saveEntityInfo(info) {
      // for (let field in info){
      //   this.currentEntity[field] = info[field];
      // }
      ShipmentService.update(this.currentEntity.id, info).then(
        (response) => {
          this.currentEntity = adjustment.adjustShipment(response);
          this.showDialogForm = false;
        }
      );
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      if (field == 'arrivedDate') {
        this.service.updateField(data.id, field, newValue).then(()=>{
          this._resetTimeout();
        });
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
    openLink(data) {
      this.$router.push({ name: 'user_shipment_detail', params: {id: data.id} })
    }
  }
}
</script>
